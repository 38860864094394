<template>
  <a-spin :spinning="state.isLoading">
    <sdPageHeader title="Mutabakat" />
    <Main>
      <Pstates class="pst">
        <a-table
          :data-source="state.shipping_company_summaries"
          :columns="state.shipping_company_summary_columns"
          row-key="id"
        />
      </Pstates>
      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <a-row type="flex">
              <a-col :span="3">
                <a-form-item name="generalSearch">
                  <a-input
                    v-model:value="state.selected.text"
                    placeholder="Arama Yap"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-select
                    v-model:value="state.selected.cs_company_id"
                    placeholder="Kargo Firması"
                    :dropdown-match-select-width="false"
                    style="width: 150px"
                  >
                    <a-select-option value="">
                      Kargo Firması
                    </a-select-option>
                    <a-select-option
                      v-for="item in state.cs_companies_for_select"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-select
                    v-model:value="state.selected.customer_id"
                    :dropdown-match-select-width="false"
                    placeholder="Kullanıcı Seçiniz"
                    style="width: 150px"
                  >
                    <a-select-option value="">
                      Kullanıcı Seç
                    </a-select-option>
                    <a-select-option
                      v-for="item in state.customers_for_select"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-range-picker
                    :value="state.selected.date"
                    format="DD/MM/YYYY"
                    @change="state.selected.date = $event"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-select
                    v-model:value="state.selected.incoming_invoice_paper_id"
                    :dropdown-match-select-width="false"
                    placeholder="Fatura Seçiniz"
                    style="width: 150px"
                  >
                    <a-select-option
                      v-for="item in state.incoming_invoice_papers"
                      :key="item.value"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-select
                    v-model:value="state.selected.state"
                    :dropdown-match-select-width="false"
                    placeholder="Onay Durumu Seçiniz"
                    style="width: 100%"
                  >
                    <a-select-option value="">
                      Onay Durumu
                    </a-select-option>
                    <a-select-option
                      v-for="(item, index) in state.approval_statuses"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-select
                    v-model:value="state.selected.return"
                    placeholder="İade Durumu Seçiniz"
                    :dropdown-match-select-width="false"
                    style="width: 100%"
                  >
                    <a-select-option value="">
                      İade Durumu
                    </a-select-option>
                    <a-select-option value="1">
                      İade
                    </a-select-option>
                    <a-select-option value="2">
                      İade Olmayan
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="3">
                <a-form-item>
                  <a-button
                    type="primary"
                    @click="get()"
                  >
                    Filtrele
                  </a-button>
                </a-form-item>
              </a-col>
            </a-row>
            <a-table
              :data-source="state.items"
              :columns="state.columns"
              row-key="id"
            >
              <template #filterIcon="filtered">
                <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
              </template>
              <template #is_approved_by_cargo_render="{ record }">
                <div class="text-center">
                  <span
                    v-if="record.is_approved_by_cargo"
                    style="color: green"
                  >
                    Onaylandı
                  </span>
                  <span
                    v-else
                    style="color: red"
                  >
                    Onay bekliyor
                  </span>
                </div>
              </template>
              <template #request_number_render="{ record }">
                <div style="text-align: left">
                  {{ record.request_number }}
                </div>
              </template>
              <template #cargo_key_render="{ record }">
                <div style="text-align: left">
                  {{ record.cargo_key }}
                </div>
              </template>
              <template #is_returned_render="{ record }">
                <span v-if="record.is_returned">İade</span>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>

import { SearchOutlined } from '@ant-design/icons-vue'
import { defineComponent, onMounted, reactive } from 'vue'
import { Pstates } from '@/view/dashboard/style'
import { Main } from '@/view/styled'
import restMethods from '@/utility/restMethods'

export default defineComponent({
  name: 'Consensus',
  components: {
    Main,
    Pstates,
    SearchOutlined,
  },
  setup() {

    const state = reactive({
      items: [],
      shipping_company_summaries: [],
      isLoading: true,

      cs_companies_for_select: [],
      customers_for_select: [],
      incoming_invoice_papers: [],
      approval_statuses: [],

      selected: {
        text: '',
        cs_company_id: '',
        customer_id: '',
        state: '',
        date: [],
        incoming_invoice_paper_id: '',
        return: '',
      },
      //FİRMA,ONAY,İADE,KAYIT SAYISI,FARKLI MÜŞTERİ,DESİ,FİYAT
      shipping_company_summary_columns: [
        {
          title: 'Firma',
          dataIndex: 'cargo_company_name',
          align: 'center',
        },
        {
          title: 'Kayıt Sayısı',
          dataIndex: 'record_count',
          align: 'center',
        },
        {
          title: 'Farklı Müşteri',
          dataIndex: 'unique_customer_count',
          align: 'center',
        },
        {
          title: 'Desi',
          dataIndex: 'total_real_cbm',
          align: 'center',
        },
        {
          title: 'Bizdeki Fiyat',
          dataIndex: 'total_cost_amount',
          align: 'center',
        },
        {
          title: 'Faturadaki Fiyat',
          dataIndex: 'total_invoice_amount',
          align: 'center',
        },
      ],
      columns: [
        {
          title: 'Kullanıcı Adı',
          dataIndex: 'user_name',
          align: 'center',
        },
        {
          title: 'Kargo Firması',
          dataIndex: 'cargo_company_name',
          align: 'center',
        },
        {
          title: 'Gelen Fatura',
          dataIndex: 'invoice_serial_number',
          align: 'center',
        },
        {
          title: 'Gönderi Numarası',
          dataIndex: 'request_number',
          align: 'center',
          slots: {
            customRender: 'request_number_render',
          },
        },
        {
          title: 'Sipariş Numarası',
          dataIndex: 'cargo_key',
          align: 'center',
          slots: {
            customRender: 'cargo_key_render',
          },
        },
        {
          title: 'Onay Durumu',
          dataIndex: 'is_approved_by_cargo',
          align: 'center',
          slots: {
            customRender: 'is_approved_by_cargo_render',
          },
        }, {
          title: 'Desi',
          dataIndex: 'result_cbm',
          align: 'center',
        }, {
          title: 'Fiyat',
          dataIndex: 'result_price',
          align: 'center',
        }, {
          title: 'Gönderi Tarihi',
          dataIndex: 'created_at',
          align: 'center',
        },
        {
          title: 'İade?',
          dataIndex: 'is_returned',
          align: 'center',
          slots: {
            customRender: 'is_returned_render',
          },
        },
      ],
    })

    const get = () => {
      state.isLoading = true
      restMethods.postForNoc('/consensus', {
        filter: state.selected,
      }).then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          state.items = data.items
          state.shipping_company_summaries = data.shipping_company_summaries

          state.cs_companies_for_select = data.cs_companies_for_select
          state.customers_for_select = data.customers_for_select
          state.incoming_invoice_papers = data.incoming_invoice_papers
          state.approval_statuses = data.approval_statuses

        }

        state.isLoading = false

      })
    }

    onMounted(() => {
      get()
    })

    return {
      state,

      get,
    }

  },
})
</script>
<style>

hr {
  border-color: #ededed;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pst {
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.pst-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.pst-filter > div {
  flex: 1;
  text-align: center;
}

.sub {
  display: block;
}

.ant-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
